// src/DocumentPage.tsx

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  useTheme,
  useMediaQuery,
  CircularProgress,
  DialogContentText,
  Link,
} from "@mui/material";

import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";

// import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
// import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { useMsal } from "@azure/msal-react";

import { useUser } from "../UserContext";
import axiosInstance from "../utils/axiosInstance";

const drawerWidth = 300;

interface Document {
  id: string;
  name: string;
  createdAt: string;
  files: DocumentFile[];
}

interface DocumentFile {
  fileName: string;
  fileSize: number;
  originalFileName?: string;
  id?: string;
  documentId?: string;
  content?: string;
  companyName?: string;
  financialPeriod?: string;
  auditorOpinion?: string;
  consideration?: {
    financial_risk: string;
    business_liquidity: string;
    debt_repayment_ability: string;
    legal_dispute_risk: string;
    governance_or_esg_issue: string;
  };
  keyTakeawayContent?: string;
  createdAt?: string;
  status?: string;
}

interface CustomPrompt {
  systemPrompt: string;
  instructionPrompt: string;
}

const MAX_FILES = 5;

const DocumentPage: React.FC = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();

  const { user, setUser } = useUser();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [newDocumentOpen, setNewDocumentOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [open, setOpen] = useState(false);
  const [openCustomPrompt, setOpenCustomPrompt] = useState(false);
  const [selectedDocumentFile, setSelectedDocumentFile] =
    useState<DocumentFile | null>(null);
  const [isDocumentFileUpdating, setIsDocumentFileUpdating] = useState(false);
  const [isCustomPromptUpdating, setIsCustomPromptUpdating] = useState(false);
  const [customPrompt, setCustomPrompt] = useState<CustomPrompt | null>(null);

  const [document, setDocument] = useState<Document | null>(null);
  const [documentName, setDocumentName] = useState("");
  const [documentFiles, setDocumentFiles] = useState<DocumentFile[]>([]);

  const [documents, setDocuments] = useState<Document[]>([]);

  const { documentId } = useParams();

  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [triggerFileInput, setTriggerFileInput] = useState(false);

  const handleAlertDialogOpen = () => {
    setOpenAlertDialog(true);
    setTriggerFileInput(false);
  };

  const handleAlertDialogClose = (confirm: boolean) => {
    setOpenAlertDialog(false);
    if (confirm) {
      setTriggerFileInput(true); // Trigger file input click
    }
  };

  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const handleNewDocumentClickOpen = () => {
    setNewDocumentOpen(true);
  };

  const handleNewDocumentClose = () => {
    setNewDocumentOpen(false);
  };

  const handleNameChange = (event: any) => {
    setDocumentName(event.target.value);
  };

  const handleClickOpen = (fileId: string) => {
    setOpen(true);
    const fetchDocumentFile = async () => {
      try {
        const { data } = await axiosInstance.get(
          `/documents/${documentId}/files/${fileId}`
        );
        setSelectedDocumentFile(data);
      } catch {
        setSelectedDocumentFile(null);
      }
    };
    if (fileId) fetchDocumentFile();
  };

  const handleCustomPromptClickOpen = () => {
    setOpenCustomPrompt(true);
    const fetchCustomPrompt = async () => {
      try {
        const { data } = await axiosInstance.get(`/prompts/default`);
        setCustomPrompt(data);
      } catch {
        setCustomPrompt(null);
      }
    };
    fetchCustomPrompt();
  };

  const handlePromptChange = (key: keyof CustomPrompt, value: string) => {
    setCustomPrompt((prevState) => {
      if (prevState) {
        return {
          ...prevState,
          [key]: value,
        };
      }
      return null;
    });
  };

  const handleCustomPromptClose = () => {
    setOpenCustomPrompt(false);
    setCustomPrompt(null);
  };

  const handleCustomPromptSubmitAndClose = () => {
    setIsCustomPromptUpdating(true);
    const updateCustomPrompt = async () => {
      try {
        const { data } = await axiosInstance.patch(`/prompts`, {
          systemPrompt: customPrompt?.systemPrompt,
          instructionPrompt: customPrompt?.instructionPrompt,
        });
        setIsCustomPromptUpdating(false);
        if (data) {
          setOpenCustomPrompt(false);
        }
      } catch {
        setIsCustomPromptUpdating(false);
      }
    };
    updateCustomPrompt();
  };

  const handleSubmitAndClose = () => {
    console.log(selectedDocumentFile);
    setIsDocumentFileUpdating(true);
    const updateDocumentFile = async () => {
      try {
        const { data } = await axiosInstance.put(
          `/documents/${documentId}/files/${selectedDocumentFile?.id}`,
          {
            consideration: selectedDocumentFile?.consideration,
            keyTakeawayContent: selectedDocumentFile?.keyTakeawayContent,
          }
        );
        setIsDocumentFileUpdating(false);
        if (data) {
          setSelectedDocumentFile(null);
          setOpen(false);
        }
      } catch {
        setIsDocumentFileUpdating(false);
      }
    };
    updateDocumentFile();
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedDocumentFile(null);
  };

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const handleDocumentFileChange =
    (path: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      // setSelectedDocumentFile((prevDocument: DocumentFile | null) => ({
      //   ...(prevDocument as DocumentFile),
      //   [field]: event.target.value,
      // }));

      setSelectedDocumentFile((prevDocument: DocumentFile | null) => {
        const keys = path.split(".");
        const updatedDocument = { ...(prevDocument as DocumentFile) };
        let current: any = updatedDocument;

        for (let i = 0; i < keys.length - 1; i++) {
          current = current[keys[i]];
        }

        current[keys[keys.length - 1]] = event.target.value;

        return updatedDocument;
      });
    };

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const uploadedFiles = event.target.files;

    if (uploadedFiles) {
      if (uploadedFiles.length > MAX_FILES) {
        alert(`You can only upload up to ${MAX_FILES} files at a time.`);
        event.target.value = ""; // Clear the input to reset the upload
        return;
      }

      const fileData = Array.from(uploadedFiles);
      const newFiles = fileData.map((file) => ({
        fileName: file.name,
        fileSize: file.size,
        status: "in-progress",
      }));
      setDocumentFiles((prevFiles) => [...prevFiles, ...newFiles]);
      event.target.value = "";

      const formData = new FormData();
      fileData.forEach((file) => {
        formData.append("files", file);
      });

      try {
        const response = await axiosInstance.post(
          `/documents/${documentId}/files`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const uploadedFiles = response.data;
        // console.log(uploadedFiles);
        // setDocumentFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);

        setDocumentFiles((prevFiles) => {
          return prevFiles.map((file) => {
            const uploadedFile = uploadedFiles.find(
              (uploaded: DocumentFile) =>
                uploaded.originalFileName ===
                (file.originalFileName || file.fileName)
            );
            // console.log(uploadedFile);
            if (uploadedFile) uploadedFile.status = "done";
            return uploadedFile ? { ...file, ...uploadedFile } : file;
          });
        });

        // if (!checking) {
        //   setChecking(true);
        // }
      } catch (error) {
        console.error("Error uploading files:", error);
      }
    }
  };

  // const handleDeleteFile = (index: number) => {
  //   if (documentFiles[index].id) {
  //     // axiosInstance.delete(`/data-storages/${storageId}/files`, {
  //     //   data: [documentFiles[index].fileId],
  //     // });
  //     setDocumentFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  //   }
  // };

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const { data } = await axiosInstance.get("/documents");
        setDocuments(data);
      } catch {
        setDocuments([]);
      }
    };
    fetchDocuments();
  }, []);

  useEffect(() => {
    setDocument(null);
    const fetchDocument = async () => {
      if (documentId) {
        try {
          const { data } = await axiosInstance.get(`/documents/${documentId}`);
          setDocument(data);

          const { name, files } = data;
          setDocumentName(name);
          setDocumentFiles(files);
        } catch {
          setDocument(null);
        }
      }
    };
    fetchDocument();
  }, [documentId]);

  const drawer = (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <Toolbar sx={{ display: "flex", gap: 1, justifyContent: "center" }}>
        <img
          src="/scb-logo.jpg"
          alt="Logo"
          style={{ height: "40px", width: "auto" }}
        />
        <img
          src="/rnd-logo.png"
          alt="Logo"
          style={{ height: "60px", width: "auto" }}
        />
      </Toolbar>
      <Divider />
      <Box p={2}>
        <Typography color="primary" variant="subtitle2">
          History
        </Typography>
      </Box>
      <List sx={{ p: 0, flex: 1, overflow: "auto" }}>
        {documents.map((document, index) => (
          <ListItemButton
            key={index}
            component="a"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              position: "relative",
              "&:hover .delete-icon": {
                display: "block",
              },
            }}
            onClick={() => {
              navigate("/document/" + document.id);
            }}
          >
            <ListItemText primary={document.name} />
            <ListItemText
              secondaryTypographyProps={{ fontSize: "12px" }}
              secondary={document.createdAt}
            />
          </ListItemButton>
        ))}
      </List>
      <Box
        p={1}
        pl={2}
        pr={2}
        gap={1}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderTop: "1px solid #eee",
        }}
      >
        <Typography sx={{ flex: 1 }}>{user?.name}</Typography>
        {user?.groups.includes("admin") && (
          <IconButton onClick={handleCustomPromptClickOpen}>
            <TuneOutlinedIcon />
          </IconButton>
        )}
        <IconButton
          onClick={() => {
            instance.logout({
              account: instance.getActiveAccount(),
              postLogoutRedirectUri: "/",
              onRedirectNavigate: (url) => {
                setUser(null);
                return false;
              },
            });
          }}
        >
          <LogoutOutlinedIcon />
        </IconButton>
      </Box>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: "#fff",
          boxShadow: "none",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          zIndex: 1,
          borderWidth: 0,
          borderStyle: "solid",
          borderColor: "rgba(0, 0, 0, 0.12)",
          borderBottomWidth: "thin",
          // boxShadow:
          //   "0px 1px 2px -1px rgba(0,0,0,0.1),0px 2px 2px 0px rgba(0,0,0,0.1),0px 1px 5px 0px rgba(0,0,0,0.1)",
        }}
      >
        <Toolbar
          sx={{
            flex: 1,
          }}
        >
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap color="primary" component="div">
            Credit Risk Managment
          </Typography>
        </Toolbar>
        {isMdUp ? (
          <Button
            sx={{ margin: 1.5 }}
            variant="outlined"
            startIcon={<PostAddOutlinedIcon />}
            // onClick={() => {
            //   navigate("/document/" + new Date().getTime())
            // }}
            onClick={handleNewDocumentClickOpen}
          >
            New Document
          </Button>
        ) : (
          <IconButton
            sx={{ mr: 1 }}
            color={"primary"}
            onClick={handleNewDocumentClickOpen}
          >
            <PostAddOutlinedIcon />
          </IconButton>
        )}
        <Divider />
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      {document ? (
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <Toolbar />
          <Box sx={{ display: "flex", flexDirection: "column" }} gap={2} mb={2}>
            <Box
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <TextField
                required
                label="Document Name"
                fullWidth
                sx={{ backgroundColor: "white" }}
                value={documentName}
                onChange={handleNameChange}
              />
              {/* <Button
              sx={{ width: 160, height: 36.5 }}
              variant="contained"
              onClick={() => {
                // Handle save action here
                alert("Saved successfully!");
              }}
            >
              Save
            </Button> */}
            </Box>

            {/* Upload File */}
            <Box display="flex" alignItems="center">
              <Button
                variant="outlined"
                component="label"
                startIcon={<AddCircleOutlineOutlinedIcon />}
                sx={{ backgroundColor: "white" }}
                onClick={handleAlertDialogOpen}
              >
                Add File
                {/* <input
                  type="file"
                  hidden
                  onChange={handleFileUpload}
                  multiple
                /> */}
              </Button>
              {triggerFileInput && (
                <input
                  type="file"
                  hidden
                  multiple
                  onChange={handleFileUpload}
                  ref={(input) => input && input.click()} // Programmatically trigger input click
                />
              )}
            </Box>
          </Box>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "200px" }}>File Name</TableCell>
                  <TableCell>Company Name</TableCell>
                  <TableCell align="center">Financial Period</TableCell>
                  <TableCell align="center">Auditor Opinion</TableCell>
                  <TableCell sx={{ width: "80px" }} align="center"></TableCell>
                  {/* <TableCell sx={{ width: "50px" }} align="center"></TableCell> */}
                  {/* <TableCell sx={{ width: "50px" }} align="center"></TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {documentFiles.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ width: "200px" }}>
                      {row.originalFileName || row.fileName}
                    </TableCell>
                    <TableCell>{row.companyName}</TableCell>
                    <TableCell align="center">{row.financialPeriod}</TableCell>
                    <TableCell align="center">{row.auditorOpinion}</TableCell>
                    <TableCell sx={{ width: "80px" }} align="center">
                      {row.status === "in-progress" ? (
                        <CircularProgress size={20} />
                      ) : (
                        <IconButton
                          aria-label="view"
                          color="primary"
                          onClick={() => handleClickOpen(row.id || "")}
                        >
                          <LaunchOutlinedIcon />
                        </IconButton>
                      )}
                    </TableCell>
                    {/* <TableCell sx={{ width: "50px" }} align="center">
                    <IconButton aria-label="print" color="primary">
                      <PrintOutlinedIcon />
                    </IconButton>
                  </TableCell> */}
                    {/* <TableCell sx={{ width: "50px" }}>
                      <IconButton
                        aria-label="delete"
                        color="error"
                        onClick={() => handleDeleteFile(index)}
                      >
                        <DeleteOutlineOutlinedIcon />
                      </IconButton>
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100vh",
            // backgroundColor: "#f5f5f5",
          }}
        >
          {documentId ? (
            <CircularProgress />
          ) : (
            <Typography>No Document</Typography>
          )}
        </Box>
      )}
      <Dialog
        open={openAlertDialog}
        onClose={() => handleAlertDialogClose(false)}
      >
        <DialogTitle>Important Notice: Upload Public Data Only</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="body2" sx={{ fontSize: "0.875rem" }}>
              Please ensure that you only upload non-confidential, publicly
              accessible data. Do not upload auditor comments or any other
              documents containing sensitive, proprietary, or confidential
              information. For more information about data protection
              regulations, please refer to the{" "}
              <Link
                href="https://pdpathailand.com/pdpa/index_eng.html"
                target="_blank"
                rel="noopener noreferrer"
                color="primary"
                underline="hover"
              >
                Personal Data Protection Act (PDPA)
              </Link>{" "}
              and the{" "}
              <Link
                href="https://www.bot.or.th/en/privacy-policy.html"
                target="_blank"
                rel="noopener noreferrer"
                color="primary"
                underline="hover"
              >
                Bank of Thailand's Personal Data Privacy Policy
              </Link>.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            sx={{ margin: 0.5, width: 80 }}
            onClick={() => handleAlertDialogClose(true)}
            variant="contained"
            color="primary"
            autoFocus
          >
            OK
          </Button>
          <Button
            sx={{ margin: 0.5, width: 80 }}
            onClick={() => handleAlertDialogClose(false)}
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        aria-hidden="false"
        aria-modal="true"
        open={open}
        onClose={handleClose}
        scroll="paper"
        fullWidth={true}
        maxWidth={"lg"}
      >
        <DialogTitle>
          Analysis Result
          {selectedDocumentFile && " - " + selectedDocumentFile.companyName}
        </DialogTitle>
        <DialogContent dividers>
          {selectedDocumentFile && (
            <>
              <Box
                sx={{
                  display: "flex",
                  pt: 1,
                  pb: 1,
                  gap: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    flex: 1,
                  }}
                >
                  <Box>
                    <Typography variant="h6" fontWeight="bold" gutterBottom>
                      Financial Risk:
                    </Typography>
                    <TextField
                      value={selectedDocumentFile.consideration?.financial_risk}
                      onChange={handleDocumentFileChange(
                        "consideration.financial_risk"
                      )}
                      multiline
                      variant="outlined"
                      fullWidth
                      sx={{
                        whiteSpace: "pre-line",
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography variant="h6" fontWeight="bold" gutterBottom>
                      Liquidity Risk:
                    </Typography>
                    <TextField
                      value={
                        selectedDocumentFile.consideration?.business_liquidity
                      }
                      onChange={handleDocumentFileChange(
                        "consideration.business_liquidity"
                      )}
                      multiline
                      variant="outlined"
                      fullWidth
                      sx={{
                        whiteSpace: "pre-line",
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography variant="h6" fontWeight="bold" gutterBottom>
                      Financial Ratio Risk:
                    </Typography>
                    <TextField
                      value={
                        selectedDocumentFile.consideration?.legal_dispute_risk
                      }
                      onChange={handleDocumentFileChange(
                        "consideration.legal_dispute_risk"
                      )}
                      multiline
                      variant="outlined"
                      fullWidth
                      sx={{
                        whiteSpace: "pre-line",
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography variant="h6" fontWeight="bold" gutterBottom>
                      ESG:
                    </Typography>
                    <TextField
                      value={
                        selectedDocumentFile.consideration
                          ?.governance_or_esg_issue
                      }
                      onChange={handleDocumentFileChange(
                        "consideration.governance_or_esg_issue"
                      )}
                      multiline
                      variant="outlined"
                      fullWidth
                      sx={{
                        whiteSpace: "pre-line",
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    flex: 1,
                  }}
                >
                  <Box>
                    <Typography variant="h6" fontWeight="bold" gutterBottom>
                      Key Takeaways
                    </Typography>
                    <TextField
                      value={selectedDocumentFile.keyTakeawayContent}
                      onChange={handleDocumentFileChange("keyTakeawayContent")}
                      multiline
                      variant="outlined"
                      fullWidth
                      sx={{
                        whiteSpace: "pre-line",
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          {/* <Button onClick={handleClose} color="primary">
            Print
          </Button> */}
          <Button
            sx={{ margin: 0.5, width: 80 }}
            variant="contained"
            onClick={handleSubmitAndClose}
            color="primary"
            disabled={isDocumentFileUpdating}
          >
            {isDocumentFileUpdating ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Save"
            )}
          </Button>
          <Button
            sx={{ margin: 0.5, width: 80 }}
            variant="outlined"
            onClick={handleClose}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        aria-hidden="false"
        aria-modal="true"
        maxWidth={"sm"}
        fullWidth={true}
        open={newDocumentOpen}
        onClose={handleNewDocumentClose}
        PaperProps={{
          component: "form",
          onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            const documentName = formJson.documentName;
            const { data } = await axiosInstance.post("/documents", {
              name: documentName,
            });
            handleNewDocumentClose();
            setDocuments((prev) => [...prev, data]);
            navigate("/document/" + data.id);
          },
        }}
      >
        <DialogTitle>Create New Document</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            required
            margin="dense"
            label="Document Name"
            name="documentName"
            fullWidth
            sx={{ backgroundColor: "white" }}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            type="submit"
            sx={{ margin: 0.5 }}
            variant="contained"
            color="primary"
          >
            Create
          </Button>
          <Button
            sx={{ margin: 0.5 }}
            variant="outlined"
            onClick={handleNewDocumentClose}
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        aria-hidden="false"
        aria-modal="true"
        open={openCustomPrompt}
        onClose={handleCustomPromptClose}
        scroll="paper"
        fullWidth={true}
        maxWidth={"lg"}
      >
        <DialogTitle>Custom Prompts</DialogTitle>
        <DialogContent dividers>
          {customPrompt && (
            <>
              <Box
                sx={{
                  display: "flex",
                  pt: 1,
                  pb: 1,
                  gap: 2,
                  flexDirection: "column",
                }}
              >
                <Box>
                  <Typography fontWeight="bold" gutterBottom>
                    System Prompt:
                  </Typography>
                  <TextField
                    value={customPrompt.systemPrompt}
                    onChange={(e) =>
                      handlePromptChange("systemPrompt", e.target.value)
                    }
                    multiline
                    variant="outlined"
                    fullWidth
                    sx={{
                      whiteSpace: "pre-line",
                    }}
                  />
                </Box>
                <Box>
                  <Typography fontWeight="bold" gutterBottom>
                    Instruction Prompt:
                  </Typography>
                  <TextField
                    value={customPrompt.instructionPrompt}
                    onChange={(e) =>
                      handlePromptChange("instructionPrompt", e.target.value)
                    }
                    multiline
                    variant="outlined"
                    fullWidth
                    sx={{
                      whiteSpace: "pre-line",
                    }}
                  />
                </Box>
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            sx={{ margin: 0.5, width: 80 }}
            variant="contained"
            onClick={handleCustomPromptSubmitAndClose}
            color="primary"
            disabled={isCustomPromptUpdating}
          >
            {isCustomPromptUpdating ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Save"
            )}
          </Button>
          <Button
            sx={{ margin: 0.5, width: 80 }}
            variant="outlined"
            onClick={handleCustomPromptClose}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DocumentPage;
